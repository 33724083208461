/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        // JavaScript to be fired on all pages
        //top menu bottom triange conatainer add
        $(".top-menu .navbar-nav>li").prepend( "<div class='triange'></div>" );

        $("#owl-demo").owlCarousel({
         navigation : false,
         paginationSpeed : 1000,
         goToFirstSpeed : 2000,
         singleItem:true
       });

        $('#menu-item-256').hover(function(){
            $(".hover-text h2.conducte").show();
        }, function() {
            $(".hover-text h2.conducte").hide();
        });
        $('#menu-item-255').hover(function(){
            $(".hover-text h2.avion").show();
        }, function() {
            $(".hover-text h2.avion").hide();
        });
        $('#menu-item-254').hover(function(){
            $(".hover-text h2.energie").show();
        }, function() {
            $(".hover-text h2.energie").hide();
        });
        $('#menu-item-252').hover(function(){
            $(".hover-text h2.forestier").show();
        }, function() {
            $(".hover-text h2.forestier").hide();
        });
        $('#menu-item-251').hover(function(){
            $(".hover-text h2.gaz").show();
        }, function() {
            $(".hover-text h2.gaz").hide();
        });
        $('#menu-item-250').hover(function(){
            $(".hover-text h2.lift").show();
        }, function() {
            $(".hover-text h2.lift").hide();
        });
        $('#menu-item-245').hover(function(){
            $(".hover-text h2.maritim").show();
        }, function() {
            $(".hover-text h2.maritim").hide();
        });
        $('#menu-item-249').hover(function(){
            $(".hover-text h2.mecanica").show();
        }, function() {
            $(".hover-text h2.mecanica").hide();
        });
        $('#menu-item-248').hover(function(){
            $(".hover-text h2.mine").show();
        }, function() {
            $(".hover-text h2.mine").hide();
        });
        $('#menu-item-253').hover(function(){
            $(".hover-text h2.fier").show();
        }, function() {
            $(".hover-text h2.fier").hide();
        });
        $('#menu-item-247').hover(function(){
            $(".hover-text h2.pod").show();
        }, function() {
            $(".hover-text h2.pod").hide();
        });
        $('#menu-item-246').hover(function(){
            $(".hover-text h2.transport").show();
        }, function() {
            $(".hover-text h2.transport").hide();
        });



        $('.page-template-template_domain_apa .subcat-slider, .page-template-template_domain_apa .footer').css('display','none');
        $('.page-template-template_domain_avioane .subcat-slider, .page-template-template_domain_avioane .footer').css('display','none');
        $('.page-template-template_domain_energie .subcat-slider, .page-template-template_domain_energie .footer').css('display','none');
        $('.page-template-template_domain_forestier .subcat-slider, .page-template-template_domain_forestier .footer').css('display','none');
        $('.page-template-template_domain_gaz .subcat-slider, .page-template-template_domain_gaz.footer').css('display','none');
        $('.page-template-template_domain_lift .subcat-slider, .page-template-template_domain_lift .footer').css('display','none');
        $('.page-template-template_domain_maritim .subcat-slider, .page-template-template_domain_maritim .footer').css('display','none');
        $('.page-template-template_domain_mecanica .subcat-slider, .page-template-template_domain_mecanica .footer').css('display','none');
        $('.page-template-template_domain_mine .subcat-slider, .page-template-template_domain_mine .footer').css('display','none');
        $('.page-template-template_domain_fierarie .subcat-slider, .page-template-template_domain_fierarie .footer').css('display','none');
        $('.page-template-template_domain_pod .subcat-slider, .page-template-template_domain_pod .footer').css('display','none');
        $('.page-template-template_domain_transport .subcat-slider, .page-template-template_domain_transport .footer').css('display','none');

      $('#filters li a').click(function () {
        $('.page-template-template_domain_apa .subcat-slider, .page-template-template_domain_apa .footer').css('display','block');
        $('.page-template-template_domain_apa .footer').addClass('footer_apa');

        $('.page-template-template_domain_avioane .subcat-slider, .page-template-template_domain_avioane .footer').css('display','block');
        $('.page-template-template_domain_avioane .footer').addClass('footer_avioane');

        $('.page-template-template_domain_energie .subcat-slider, .page-template-template_domain_energie .footer').css('display','block');
        $('.page-template-template_domain_energie .footer').addClass('footer_energie');

        $('.page-template-template_domain_forestier .subcat-slider, .page-template-template_domain_forestier .footer').css('display','block');
        $('.page-template-template_domain_apa .forestier').addClass('footer_forestier');

        $('.page-template-template_domain_gaz .subcat-slider, .page-template-template_domain_gaz.footer').css('display','block');
        $('.page-template-template_domain_gaz .footer').addClass('footer_gaz');

        $('.page-template-template_domain_lift .subcat-slider, .page-template-template_domain_lift .footer').css('display','block');
        $('.page-template-template_domain_lift .footer').addClass('footer_lift');

        $('.page-template-template_domain_maritim .subcat-slider, .page-template-template_domain_maritim .footer').css('display','block');
        $('.page-template-template_domain_maritim .footer').addClass('footer_maritim');

        $('.page-template-template_domain_mecanica .subcat-slider, .page-template-template_domain_mecanica .footer').css('display','block');
        $('.page-template-template_domain_mecanica .footer').addClass('footer_mecanica');

        $('.page-template-template_domain_mine .subcat-slider, .page-template-template_domain_mine .footer').css('display','block');
        $('.page-template-template_domain_mine .footer').addClass('footer_mine');

        $('.page-template-template_domain_fierarie .subcat-slider, .page-template-template_domain_fierarie .footer').css('display','block');
        $('.page-template-template_domain_fierarie .footer').addClass('footer_fierarie');

        $('.page-template-template_domain_pod .subcat-slider, .page-template-template_domain_pod .footer').css('display','block');
        $('.page-template-template_domain_pod .footer').addClass('footer_pod');

        $('.page-template-template_domain_transport .subcat-slider, .page-template-template_domain_transport .footer').css('display','block');
        $('.page-template-template_domain_transport .footer').addClass('footer_transport');


        var offset = 20; //Offset of 20px

        $('html, body').animate({
            scrollTop: $("#portfolio").offset().top + offset
        }, 2000);
      });

        //animation
        new WOW().init();

      //menu with dropdown


        if ($("body").hasClass("page-template-template_domain_forestier")) {
             // Grid.init();
        }
        //single page carousel and fancybox to products
         if ($("body").hasClass("single")) {
            $("#photogalery").owlCarousel({
              autoPlay: false,
              items : 5,
              itemsDesktop : [1199,4],
              itemsDesktopSmall : [979,3],
              itemsMobile: [479, 2],
              margin: 20,
              navigation: true,
              pagination: false,
              navigationText: ['<i class="fa fa-chevron-left"></i>','<i class="fa fa-chevron-right"></i>']
            });
             $('.fancybox').fancybox({
              padding : 0,
              openEffect  : 'elastic'
            });
         }

        if ($("body").hasClass("single-product") || $("body").hasClass("page-template-template_domain_forestier")) {
          $('table').addClass('table table-bordered');
          $("#single-gallery").owlCarousel({
            autoPlay: false,
            items : 5,
            itemsDesktop : [1199,4],
            itemsDesktopSmall : [979,3],
            itemsMobile: [479, 2],
            margin: 20,
            navigation: true,
            pagination: false,
            navigationText:	['<i class="fa fa-chevron-left"></i>','<i class="fa fa-chevron-right"></i>']
          });
          $("#relatedprod").owlCarousel({
            autoPlay: false,
            items : 4,
            itemsDesktop : [1199,4],
            itemsDesktopSmall : [979,3],
            itemsMobile: [479, 2],
            margin: 20,
            navigation: true,
            pagination: false,
            navigationText:	['<i class="fa fa-chevron-left"></i>','<i class="fa fa-chevron-right"></i>']
          });
          $('.fancybox').fancybox({
            padding : 0,
            openEffect  : 'elastic'
          });
        }

        if ($("body").hasClass("page-template-template_company_static") || $("body").hasClass("page-template-template_staticbase")) {
         $("#photogalery").owlCarousel({
          autoPlay: false,
          items : 5,
          itemsDesktop : [1199,4],
          itemsDesktopSmall : [979,3],
          itemsMobile: [479, 2],
          margin: 20,
          navigation: true,
          pagination: false,
          navigationText:	['<i class="fa fa-chevron-left"></i>','<i class="fa fa-chevron-right"></i>']
        });
         $('.fancybox').fancybox({
          padding : 0,
          openEffect  : 'elastic'
        });

       }


       if ($("body").hasClass("page-template-template_blog")) {
         $("#mostreaded").owlCarousel({
          autoPlay: false,
          items : 5,
          itemsDesktop : [1199,4],
          itemsDesktopSmall : [979,3],
          itemsMobile: [479, 2],
          margin: 20,
          navigation: true,
          pagination: false,
          navigationText:	['<i class="fa fa-chevron-left"></i>','<i class="fa fa-chevron-right"></i>']
        });
       }

       $(".modal-fullscreen").on('show.bs.modal', function () {
        setTimeout( function() {
          $(".modal-backdrop").addClass("modal-backdrop-fullscreen");
        }, 0);
      });
      $(".modal-fullscreen").on('hidden.bs.modal', function () {
        $(".modal-backdrop").addClass("modal-backdrop-fullscreen");
      });

       var $container = $('#portfolio'),

	 		 // create a clone that will be used for measuring container width
	 		 $containerProxy = $container.clone().empty().css({ visibility: 'hidden' });

       //$container.after( $containerProxy );

			    // get the first item to use for measuring columnWidth
         var $item = $container.find('.portfolio-item').eq(0);
			    $container.css({
           })
			    .isotope({
			      resizable: false,
         });

      $('.product-item').matchHeight();
      $('.product-item h2').matchHeight();


			// filter items when filter link is clicked
			$('#filters a').click(function(){

       $('#filters a.active').removeClass('active');
       var selector = $(this).attr('data-filter');
       $container.isotope({ filter: selector, animationEngine : "css" });
       $(this).addClass('active');
       return false;

     });

     $('.single-product table').addClass('table table-bordered');

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
	              // JavaScript to be fired on the home page

	    //animation
	    new WOW().init();

      //footer-cookie


	    //load more domains
      size_lid = $("#domains li").size();
      y=12;
      $('#domains li:lt('+y+')').show();

      $('#loadMored').click(function () {
       y = (y+3 <= size_lid) ? y+3 : size_lid;
       $('#domains li:lt('+y+')').show();
       $('#loadMored').hide();
     });


		//load more prod recomandate
		size_li = $("#recentp li").size();
   x=3;
   $('#recentp li:lt('+x+')').show();

   $('#loadMore').click(function () {
     x= (x+6 <= size_li) ? x+6 : size_li;
     $('#recentp li:lt('+x+')').show();
     $('#loadMore').hide();
   });



 },
 finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'despre-noi': {
      init: function() {
        // JavaScript to be fired on the about us page

      }
    },
    'help':{
        init: function() {
        // JavaScript to be fired on the about us page
        //update these values if you change these breakpoints in the style.css file (or _layout.scss if you use SASS)
  var MqM= 768,
    MqL = 1024;

  var faqsSections = $('.cd-faq-group'),
    faqTrigger = $('.cd-faq-trigger'),
    faqsContainer = $('.cd-faq-items'),
    faqsCategoriesContainer = $('.cd-faq-categories'),
    faqsCategories = faqsCategoriesContainer.find('a'),
    closeFaqsContainer = $('.cd-close-panel');

  //select a faq section
  faqsCategories.on('click', function(event){
    event.preventDefault();
    var selectedHref = $(this).attr('href'),
      target= $(selectedHref);
    if( $(window).width() < MqM) {
      faqsContainer.scrollTop(0).addClass('slide-in').children('ul').removeClass('selected').end().children(selectedHref).addClass('selected');
      closeFaqsContainer.addClass('move-left');
      $('body').addClass('cd-overlay');
    } else {
          $('body,html').animate({ 'scrollTop': target.offset().top - 19}, 200);
    }
  });

  //close faq lateral panel - mobile only
  $('body').bind('click touchstart', function(event){
    if( $(event.target).is('body.cd-overlay') || $(event.target).is('.cd-close-panel')) {
      closePanel(event);
    }
  });
  faqsContainer.on('swiperight', function(event){
    closePanel(event);
  });

  //show faq content clicking on faqTrigger
  faqTrigger.on('click', function(event){
    event.preventDefault();
    $(this).next('.cd-faq-content').slideToggle(200).end().parent('li').toggleClass('content-visible');
  });

  //update category sidebar while scrolling
  $(window).on('scroll', function(){
    if ( $(window).width() > MqL ) {
      (!window.requestAnimationFrame) ? updateCategory() : window.requestAnimationFrame(updateCategory);
    }
  });

  $(window).on('resize', function(){
    if($(window).width() <= MqL) {
      faqsCategoriesContainer.removeClass('is-fixed').css({
        '-moz-transform': 'translateY(0)',
          '-webkit-transform': 'translateY(0)',
        '-ms-transform': 'translateY(0)',
        '-o-transform': 'translateY(0)',
        'transform': 'translateY(0)',
      });
    }
    if( faqsCategoriesContainer.hasClass('is-fixed') ) {
      faqsCategoriesContainer.css({
        'left': faqsContainer.offset().left,
      });
    }
  });

  function closePanel(e) {
    e.preventDefault();
    faqsContainer.removeClass('slide-in').find('li').show();
    closeFaqsContainer.removeClass('move-left');
    $('body').removeClass('cd-overlay');
  }

  function updateCategory(){
    updateCategoryPosition();
    updateSelectedCategory();
  }

  function updateCategoryPosition() {
    var topp = $('.cd-faq').offset(),
      height = jQuery('.cd-faq').height() - jQuery('.cd-faq-categories').height(),
      margin = 20;
    if( topp - margin <= $(window).scrollTop() && topp - margin + height > $(window).scrollTop() ) {
      var leftValue = faqsCategoriesContainer.offset().left,
        widthValue = faqsCategoriesContainer.width();
      faqsCategoriesContainer.addClass('is-fixed').css({
        'left': leftValue,
        'top': margin,
        '-moz-transform': 'translateZ(0)',
          '-webkit-transform': 'translateZ(0)',
        '-ms-transform': 'translateZ(0)',
        '-o-transform': 'translateZ(0)',
        'transform': 'translateZ(0)',
      });
    } else if( topp - margin + height <= $(window).scrollTop()) {
      var delta = topp - margin + height - $(window).scrollTop();
      faqsCategoriesContainer.css({
        '-moz-transform': 'translateZ(0) translateY('+delta+'px)',
          '-webkit-transform': 'translateZ(0) translateY('+delta+'px)',
        '-ms-transform': 'translateZ(0) translateY('+delta+'px)',
        '-o-transform': 'translateZ(0) translateY('+delta+'px)',
        'transform': 'translateZ(0) translateY('+delta+'px)',
      });
    } else {
      faqsCategoriesContainer.removeClass('is-fixed').css({
        'left': 0,
        'top': 0,
      });
    }
  }

  function updateSelectedCategory() {
    faqsSections.each(function(){
      var actual = $(this),
        //margin = parseInt($('.cd-faq-title').eq(1).css('marginTop').replace('px', '')),
        activeCategory = $('.cd-faq-categories a[href="#'+actual.attr('id')+'"]'),
        topSection = (activeCategory.parent('li').is(':first-child')) ? 0 : Math.round(actual.offset().top);

      if ( ( topSection - 20 <= $(window).scrollTop() ) && ( Math.round(actual.offset().top) + actual.height() + margin - 20 > $(window).scrollTop() ) ) {
        activeCategory.addClass('selected');
      }else {
        activeCategory.removeClass('selected');
      }
    });
  }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
